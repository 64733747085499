import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">What makes Coinsclone the Ideal <span className="bluecolor">STO Development Company?</span></h2>
              <p className='text-center pharagraph'>
              We, Coinsclone are a reputed STO Development company with extensive profit-making solutions and worthy ideas. The security tokens we develop keep investors engaged and have a high brand value in the market. Additionally, our STO platform is specially designed to raise funds in an accelerated way and can take you to greater heights. Take a consultation with our STO developers for effective Security token offering development services.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/sto/what-make-sto-development-company.webp"
                alt="best sto development company"
                placeholder='none'
                className='mt-3'
                width={350}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">As a premier STO Development Company, we have the best resources to deliver top-notch results. Our clients have raised sizeable funds through reliable STO fundraising techniques. We design and develop STO platforms based on extensive research work to guarantee success. Additionally, here are some of the top reasons why you should approach us.
              </p>
              <ul>
                <li>24/7 Maintenance and support system</li>
                <li>Advanced clone scripts for unique products</li>
                <li>Streamlined development process</li>
                <li>Scalability and quality assurance</li>
                <li>Affordability and transparency to clients</li>
                <li>Constant tracking and progress monitoring</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose