import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class FeaturesOf extends React.Component {

  render() {
    return (

      <section className="process-of sto pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="heading-h3"><span className="bluecolor">Features Of</span> Our Security Token Offering (STO)</span>
              Development Services
            </h2>
            <p className="text-center">
            Our STO development company has covered the best-in-class features for smooth operation. These STO features fulfill both the criteria of attractiveness and effectiveness. Here are those features. 
            </p>
            <div className="d-lg-flex add-one">
              <div className="square" >
                <h3>Automated SEC compliance</h3>
                <p className="pharagraph">
                During STO development, the security tokens we develop come under the SEC (Securities and Exchange Commission) compliance regulatory guidelines. Hence, it helps you to present the investors with an assurance about the security tokens.
                </p>
              </div>
              <div className="square">
                <h3>KYC/AML</h3>
                <p className="pharagraph">
                We integrate KYC(Know your customer) and AML (Anti-money laundering) features in the STO platform to allow only validated users. This increases the value of the STO project as well as safeguards from any kind of forgery activity.
                </p>
              </div>
              <div className="square" >
                <h3>Investor Dashboard</h3>
                <p className="pharagraph">
                Our STO development process includes an attractive and comprehensive investor dashboard for startups. Through this feature, investors can view their dividend payments, holdings, transaction history, voting rights, and many more relevant information.
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-two">
              <div className="square" >
                <h3>Customized smart contracts</h3>
                <p className="pharagraph">
                We tailor smart contracts according to your requirements while developing security tokens. The customized smart contract feature enables you to define and determine how the token can be purchased, traded, and sold.
                </p>
              </div>
              <div className="square" >
                <h3>STO Wallet integration</h3>
                <p className="pharagraph">
                The wallet is a significant feature during the STO fundraising technique for secure storage, management, and transfer of security tokens. By supporting STO wallets, we enable a new way of improving your user experience.
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-three">
              <div className="square" >
                <h3>No intermediary</h3>
                <p className="pharagraph">
                In our STO process, there is no involvement of third-party intermediaries like a cryptocurrency broker. We conduct STO fundraising through a decentralized platform between the investors and startups.
                </p>
              </div>
              <div className="square">
                <h3>Rise in token liquidity</h3>
                <p className="pharagraph">
                Security tokens usually have a higher range of liquidity rather than normal digital tokens which is a great feature to have. Thus, our STO development services encourage the future of fundraising mechanisms through tokens.
                </p>
              </div>
              <div className="square" >
                <h3>Token sale mechanism</h3>
                <p className="pharagraph">
                Apart from just attracting investors through our STO development, we conduct different token sale sessions to boost popularity. With this feature, you can carry out top-quality token sales to investors and enlarge your profit levels.
                </p>
              </div>
            </div>
            <div className="mt-3 text-center">
                <ButtonComponent />
            </div>
          </div>
      </section>

    )
  }
}

export default FeaturesOf
