import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor sto pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="heading-h3"><span className="bluecolor">Benefits Of </span>Our STO Development Services</span>
            </h2>
            <p className="text-center">
            Our prominent development company offers multiple benefits to startups to launch a successful STO platform. From providing transparency to advanced security, our STO development can be quite handy. Here are the phenomenal benefits offered by our STO development services company.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Reduced costs</h3>
                <p className="pharagraph">
                Compared to other traditional fundraising mechanisms, STO development has cost cutdowns on areas like legal expenses and administrative overhead. Hence, the overall cost needed for the STO process is reduced considerably as well.
                </p>
              </div>
              <div className="square" >
                <h3>Ownership and rights</h3>
                <p className="pharagraph">
                The investors in the STO project can get a sense of ownership and rights with the security tokens offered. Additionally, the investors can also enjoy the profit shares which becomes a huge bonus if the project is successful.
                </p>
              </div>
              <div className="square" >
                <h3>Global participation</h3>
                <p className="pharagraph">
                Investors from around the globe get to participate in the STO without much restrictions. Thanks to blockchain technology, transactions are recorded on a digital ledger and are transparent to everyone which attracts investors from different parts of the world.
                </p>
              </div>
              <div className="square" >
                <h3>Intense security</h3>
                <p className="pharagraph">
                Usually, blockchain-powered platforms have high security and STO platforms are no different. Hence, STO fundraising doesn’t involve many scams or fraudulent activities which attracts a wider range of investors.
                </p>
              </div>
              <div className="square" >
                <h3>Enhanced market</h3>
                <p className="pharagraph">
                Since STO involves security tokens, the Crypto market is enhanced with improved liquidity, better transaction rates, and cost reduction. This is largely due to the trust in security tokens which in turn increases the number of investments.
                </p>
              </div>
              <div className="square" >
                <h3>Simpler to access</h3>
                <p className="pharagraph">
                STO fundraising mechanism is comparatively simpler to access than other fundraising techniques like IPO (Initial public offering) for stock exchanges. The main reason behind simpler accessibility among investors is the digital tokenization of the company’s assets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
