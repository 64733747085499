import React from 'react'


const TypeOf = () => {


  return (
    <section className="typeof pt-100 mb-0">
      <div className='gray-bg'>
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Types of</span> Security Tokens Offered By Our STO Development Company
            </h2>
            <p className="pharagraph head text-center">Coinsclone offers different kinds of security tokens with unique and specialized purposes on each of them. Here are the types of security tokens we offer for our clients around the globe.
            </p>
        </div>
        <div className='row popular we-offer'>
            <div className='col-md-12 col-lg-3'>
                <h3>Equity Tokens</h3>
                <p className='pharagraph'>Equity tokens reflect the ownership of real-world assets in the form of shares or equity in a company. The equity tokens we develop can enlarge your business prospects by providing investors with digital ownership rights.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Debt Tokens</h3>
                <p className='pharagraph'>Our STO development services include tokenizing debt-based instruments like real-world bonds, loans, etc. Businesses can raise funds by issuing digital debt tokens with interest charges applied for every delay of debt return.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Asset-Backed Tokens</h3>
                <p className='pharagraph'>We build asset tokens that are backed by precious ornaments like gold, diamonds, or instead real estate properties as well. These assets can be both tangible or intangible which is a major plus of building asset-backed tokens.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Hybrid tokens</h3>
                <p className='pharagraph'>Hybrid tokens are a mix of all three types of security tokens, namely equity, debt, and asset-backed. We develop hybrid tokens that offer flexibility, and innovations and still come under regulatory compliance. </p>
            </div>
        </div>
      </div>
      </div>
    </section>
  )
}

export default TypeOf