import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>STO</span> Development Company</h1>
                <p className="pharagraph mb-0">Launch a modernized and attractive Security token offering platform to build your brand and raise funds for your business. Coinsclone, a leading STO development company will guide you in the ideal direction with a top-notch security token offering development services. We provide invaluable STO solutions to advance your Crypto business progress with the best security. </p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/sto/sto-development-company.webp"
                alt="sto development company"
                className='d-none d-md-block float-end'
                width={543}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection