import React from 'react'



class UseCase extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use sto pt-100 secureof">
        <div className="container">
          <h2 className="main-heading">Our STO <span className='bluecolor'>Development Services</span></h2>
          <p className='text-center'>Coinsclone offers definitive and organized STO development services that can build greater investor trust for your project. Acquire our STO services and get ready to enlarge your financial status through a proven fundraising strategy.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                STO Platform/Website Development 
                  </h3>
                <p className="pharagraph">We create STO websites with precise planning and high-end security for increased business value in the market. Our STO websites have an engaging UI/UX interface to enhance token distribution. </p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Smart contract development
                </h3>
                <p className="pharagraph">Our professional developers construct accurate smart contracts to automate processes involving trading and monitoring activities. Our smart contracts have wide applications in STO development especially while accepting or rejecting transactions.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                White paper creation
                </h3>
                <p className="pharagraph">We develop white papers with the entire technical and financial information about the project in a prolific way. Our white papers have produced the best results since we define the purpose, execute the problem summary, and comprehensively depict the solution overview.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                STO Marketing
                </h3>
                <p className="pharagraph">Through our STO Marketing services, we offer a clear picture of our project and token sale to the investors. The STO marketing services we include are social media optimization, PR creation, influencer marketing, etc.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                STO Consulting
                </h3>
                <p className="pharagraph">Our STO Consulting services help out and guide businesses through the process of STO development. Also, we contribute technical insights and in-depth knowledge about Security token offerings to the necessary clients.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Security Token Development
                </h3>
                <p className="pharagraph">We define the token purpose elaborately and design tokens with potent features according to different token standards like ERC-1400, ERC 3643, etc. These tokens have the backing of real-world assets like shares (equity), real estate, and commodities.</p>
              </div>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UseCase