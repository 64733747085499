import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const DevProcess = () => {


  return (
    <section className="customs sto pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center">Our STO <span className="bluecolor">Development Process</span>
            </h2>
            <p className='text-center'>We follow six crucial steps as far during STO development where we design, develop, and launch a standardized STO. These steps play a vital role in the overall project coming out on the positive side. </p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Locating requirements</h3>
                    <p class="pharagraph">Here, we gather the entire necessities from the clients and make a collective decision on how to enhance their business. In terms of STO development, we make a list of the essential commodities required as the first step.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Solutions Designing</h3>
                    <p class="pharagraph">Once we gather the essentials, designing solutions based on requirements will be our next goal. From the proper research conducted, we align strategies and STO design solutions along with defining the legal consultations.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Coding development</h3>
                    <p class="pharagraph">We prepare the backend codes in the development phase for the STO platform. The development phase is critical and we constantly update clients on what’s in store. We divide the STO or security token development phases into three stages.</p>
                </div>
                <div className='custom-blk'>
                    <h3>QA Testing</h3>
                    <p class="pharagraph">Quality assurance testing is carried out in this phase where our developers verify the credibility. This phase is to ensure that the security tokens or the STO platform has come out well with greater potential.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Deployment</h3>
                    <p class="pharagraph">Our newly developed STO platform has to be deployed on the right server after approval from the client. For any kind of server hosting, we look after the whole process. The security token has to be listed on a popular exchange.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Support and Maintenance</h3>
                    <p class="pharagraph">Finally, once the platform is launched and deployed on the server, we provide additional assistance and 24/7 support. This helps in the STO platform maintenance for long-term survival and effective working.</p>
                </div>
            </div>
        </div>
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default DevProcess